
import { defineComponent } from 'vue'
import UserModel from '@/models/UserModel'
import { useToast } from 'vue-toastification'
import moment from 'moment'

export default defineComponent({
  name: 'Verify Email',
  setup() {
    const toast = useToast()
    return {
      toast,
    }
  },
  data() {
    return {
      isLoading: true,
      isExpired: false,
      formVerify: {
        code: '' as any,
      },
      emailRequest: '',
      errorFieldEmail: false,
      errorEmailMessage: '',
      requestProcess: false,
      countdown: 0 as any,
    }
  },
  async created() {
    if (this.$route.query.from == 'register') {
      this.countdown = 60
    }
    if (this.$route.query.token) {
      this.formVerify.code = this.$route.query.token
      await this.checkToken()
    } else {
      this.$route.query.token = ''
      this.isExpired = true
      this.isLoading = false
    }
    this.countDownTimer()
  },
  computed: {
    getCounter(): any {
      return localStorage.getItem('counter')
    },
    checkEmail(): boolean {
      if (
        //eslint-disable-next-line
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailRequest)
      ) {
        return true
      } else {
        return false
      }
    },
    formattedCountdown(): any {
      return moment.utc(60).format('mm:ss')
    },
  },
  methods: {
    async checkToken() {
      await UserModel.checkVerifyEmail(this.formVerify.code)
        .then(res => {
          this.isLoading = false
          if (res.success) {
            localStorage.setItem('counter', 0 as any)
            setTimeout(() => {
              this.$router.push('/login')
            }, 5000)
            this.isExpired = false
          } else {
            this.$route.query.token = ''
            this.isExpired = true
          }
        })
        .catch(err => {
          this.toast.error('Link kadaluwarsa, Silahkan kirim ulang email.')
          this.$route.query.token = ''
          this.isLoading = false
          this.isExpired = true
        })
    },
    async requestVerify() {
      this.requestProcess = true
      await UserModel.requestVerify(this.emailRequest)
        .then(res => {
          this.requestProcess = false
          if (res.success) {
            this.emailRequest = ''
            this.toast.success(
              'Link Verifikasi Email terkirim, Silahkan cek email Anda'
            )
            localStorage.setItem('counter', 60 as any)
            this.countdown = 60
            this.countDownTimer()
          } else {
            this.toast.error('Terjadi kesalahan, Silahkan coba kembali.')
          }
        })
        .catch(err => {
          this.toast.error(err.response.data.errors[0].message)
          this.requestProcess = false
        })
    },
    countDownTimer() {
      if (this.countdown > 0) {
        setTimeout(() => {
          this.countdown = this.countdown - 1
          this.countDownTimer()
        }, 1000)
      }
      // if (this.getCounter > 0) {
      //   setTimeout(() => {
      //     const counter: any = this.getCounter - 1
      //     localStorage.setItem('counter', (counter) as any)
      //     this.countDownTimer()
      //   }, 1000);
      // }
    },
  },
})
